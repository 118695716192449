body {
  color: #5B5353;
  font-family: 'Lato', sans-serif;
  padding: 0;
  margin: 0; }
  body a {
    color: #B45542;
    text-decoration: none; }
    body a:hover {
      color: #C1A944; }
  body p {
    line-height: 140%; }
  body h2, body h3, body h4 {
    font-family: 'Merriweather', serif;
    font-weight: normal;
    line-height: 1.4em; }
  body h3 span {
    font-size: 1em; }
  body h4 {
    font-size: 1em;
    margin-bottom: .8em;
    margin-top: 2em; }
  body h2 {
    margin-bottom: 1.4em;
    font-size: 1.6em;
    color: #739595; }
    body h2:after {
      content: ' ';
      width: 2em;
      display: block;
      position: absolute;
      height: 2px;
      background-color: #C1A944;
      margin-top: 10px; }
  body h3 {
    padding-top: 2em; }
  body section {
    width: 100%;
    box-sizing: border-box;
    padding: 2em; }
  body ul {
    margin: 2em 2em 2em 0em;
    list-style: none; }
  body main {
    padding-top: 70px; }
  body section ul li {
    line-height: 140%;
    margin: .3em 0; }
    body section ul li:before {
      content: "\2022";
      color: #B45542;
      /* Change the color */
      font-weight: bold;
      /* If you want it to be bold */
      display: inline-block;
      /* Needed to add space between the bullet and the text */
      width: 1em;
      /* Also needed for space (tweak if needed) */
      margin-left: -1em;
      /* Also needed for space (tweak if needed) */ }
  body .breakout {
    border: 1px solid #CCC;
    padding: 1em;
    font-weight: 700;
    color: #B45542;
    margin: 2em 0; }
  body .home {
    background: #E0DCCD;
    color: #555; }
    body .home h2 {
      margin-top: 1.5em; }
    body .home blockquote {
      font-style: italic;
      padding: 1em;
      margin: 0; }
    body .home ul li a {
      font-size: 1.2em;
      text-decoration: underline; }
  body .phone-cta {
    font-style: normal;
    font-size: 2em; }
    body .phone-cta.wa {
      font-size: 1.2em; }
  body .home-visits {
    background: #D3EEEE; }
    body .home-visits blockquote {
      font-style: italic;
      padding: 1em;
      margin: 0; }
  body .contact {
    border-top: 1px solid #CCC; }
  body .bav, body .faqs {
    background: #D3EEEE; }
    body .bav a, body .faqs a {
      color: #B45542; }
  body .faqs img {
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 40em) {
      body .faqs img {
        width: 560px;
        height: auto;
        margin: 0 auto;
        display: block; } }
  body .bav iframe {
    width: 100%;
    min-height: 315px;
    padding-top: 2em; }
    @media only screen and (min-width: 50em) {
      body .bav iframe {
        width: 560px;
        height: 315px;
        margin: 0 auto;
        display: block; } }
  body address {
    margin: 2em;
    margin-top: 0; }
  @media only screen and (min-width: 50em) {
    body section .container {
      width: 800px;
      margin: 0 auto;
      padding-bottom: 2.4em;
      font-size: 1.2em; }
    body h2 {
      margin-bottom: 3em; }
    body h2:after {
      width: 800px;
      height: 2px; }
    body .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%; }
      body .row h2 {
        width: 100%; }
      body .row > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-right: 1em; }
    body .contact .breakout {
      text-align: center; }
    body .contact address {
      margin-left: 0; } }
