header {
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 70px; }
  header.active .wrapper:after {
    opacity: 1; }
  header.active .wrapper button {
    background-image: url(src/images/close.svg); }
  header.active nav {
    pointer-events: auto;
    opacity: 1; }
  header .wrapper {
    background: #FFF;
    height: 70px;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    position: absolute;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.58); }
    header .wrapper .inner {
      position: relative; }
    header .wrapper h1 {
      padding: 0;
      margin: 0;
      font-weight: 500;
      font-size: 1.5em;
      color: white;
      text-align: center;
      width: 167px;
      height: 48px;
      background: transparent url(src/images/london-bridge-chiropractic.png) center center no-repeat;
      background-size: 100%;
      margin-left: 1em;
      margin-top: 11px; }
      header .wrapper h1 a {
        width: 200px;
        height: 70px;
        display: block;
        text-indent: -9999px; }
    header .wrapper button {
      position: absolute;
      right: 2em;
      top: 16px;
      width: 25px;
      height: 20px;
      text-indent: -9999px;
      border: 0;
      display: block;
      background: transparent url(src/images/hamburger.svg) center center no-repeat;
      background-size: 100% 100%;
      outline: none;
      cursor: pointer; }
  header nav {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #E0DCCD;
    text-align: center;
    font-size: 1.2em;
    font-weight: 300;
    margin: 0;
    position: fixed;
    opacity: 0;
    transition: 0.3s opacity;
    left: -100%;
    z-index: 1; }
    header nav.reveal {
      left: auto; }
    header nav ul {
      padding: 25px;
      margin: 0;
      list-style-type: none;
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
      header nav ul li {
        line-height: 220%;
        opacity: 1;
        font-family: 'Merriweather', serif; }

@media only screen and (min-width: 50em) {
  header .wrapper .inner {
    width: 800px;
    margin: 0 auto; }
  header .wrapper h1 {
    margin-left: 0; }
  header .wrapper button {
    right: 0; }
  nav ul li {
    font-size: 1.6em; } }
